import axios from 'axios';
import { pick, isEmpty, cloneDeep, omit } from '../../utils/lodash';
import { authMsg, getCommonError } from '../../utils';
import {
  getUserProfile,
  getUserProfileOnly,
  setOrgGoalDocument,
} from '../actions';
import { LIMIT, ORGANISATION_CORE_DATA } from '../../utils/constant';
import {
  assignProjectVenue,
  assignProjectContractor,
  getProjectVenue,
  getProjectContractor,
  assignProjectPartner,
  getProjectPartner,
  updateProjectPartner,
} from '../modules/activity';
import history from '../../utils/history';

/*** TYPES ***/
const SET_ORG_ADMINS_LOADING = 'SET_ORG_ADMINS_LOADING';
const SET_ORG_LOADING = 'SET_ORG_LOADING';
const SET_ORG_LOADING_CUSTOM = 'SET_ORG_LOADING_CUSTOM';
const SET_ORGANIZATION = 'SET_ORGANIZATION';
const ORG_GOALS = 'ORG_GOALS';
const ORG_ALL_GOALS = 'ORG_ALL_GOALS';
const ORG_ALL_GOALS_LOADING = 'ORG_ALL_GOALS_LOADING';
const ORG_GOALS_REFERENCES = 'ORG_GOALS_REFERENCES';
const SET_ORG_USERS = 'SET_ORG_USERS';
const SET_ORG_GUEST_LOADING = 'SET_ORG_GUEST_LOADING';
const SET_ORG_GUEST_USERS_LIST = 'SET_ORG_GUEST_USERS_LIST';
const SET_ORG_USERS_LOADING = 'SET_ORG_USERS_LOADING';
const SET_ORG_USERS_LIST = 'SET_ORG_USERS_LIST';
const SET_ORG_PROJECTS = 'SET_ORG_PROJECTS';
const SET_ORG_VENUES = 'SET_ORG_VENUES';
const SET_ORG_CONTRACTORS = 'SET_ORG_CONTRACTORS';
const SET_ORG_PARTNERS = 'SET_ORG_PARTNERS';
const SET_ORG_ACTIVITY_STATS = 'SET_ORG_ACTIVITY_STATS';
const SET_ORG_CHECKLIST_PLAN = 'SET_ORG_CHECKLIST_PLAN';
const SET_ORG_CHECKLIST_REPORT = 'SET_ORG_CHECKLIST_REPORT';
const SET_ORGANIZATION_CORE_DATA = 'SET_ORGANIZATION_CORE_DATA';

const SET_ORG_LOADING_REASSIGN_ALL = 'SET_ORG_LOADING_REASSIGN_ALL';
const SET_ORG_LOADING_REASSIGN_AN_ACTIVITY =
  'SET_ORG_LOADING_REASSIGN_AN_ACTIVITY';

const SET_ALL_ORG = 'SET_ALL_ORG';
const SET_ORG_ADMINS = 'SET_ORG_ADMINS';

const SET_USER_OWNED_ACTIVITY = 'SET_USER_OWNED_ACTIVITY';
const SET_USER_INVITED_ACTIVITY = 'SET_USER_INVITED_ACTIVITY';

const SET_CARD_LOADING = 'SET_CARD_LOADING';
const END_SUB_LOADING = 'END_SUB_LOADING';
const START_SUB_LOADING = 'START_SUB_LOADING';

const SET_INVOICE = 'SET_INVOICE';
const SET_INVOICE_LOADING = 'SET_INVOICE_LOADING';
const GET_USER_ORG_EMPTY_MSG = 'GET_USER_ORG_EMPTY_MSG';
const SET_CORE_DATA_REMOVE_LOADING = 'SET_CORE_DATA_REMOVE_LOADING';
const SET_GUEST_USER_CONTRACTORS = 'SET_GUEST_USER_CONTRACTORS';
const SET_GUEST_USER_VENUES = 'SET_GUEST_USER_VENUES';
const SET_CROSS_REF_DOC_LOADING = 'SET_CROSS_REF_DOC_LOADING';
const SET_CROSS_REF_DOCUMENTS = 'SET_CROSS_REF_DOCUMENTS';
const SET_ORG_CURRENT_CROSS_REF_DATA = 'SET_ORG_CURRENT_CROSS_REF_DATA';
const ORGANISATION_GOALS_REFERENCES_LOADING =
  'ORGANISATION_GOALS_REFERENCES_LOADING';
const ORGANISATION_GOALS_REFERENCES_MODIFICATION_LOADING =
  'ORGANISATION_GOALS_REFERENCES_MODIFICATION_LOADING';

const STRATEGIC_DOCUMENTS_LOADING = 'STRATEGIC_DOCUMENTS_LOADING';
const STRATEGIC_DOCUMENTS = 'STRATEGIC_DOCUMENTS';
const SET_POST_ORG_DOC_LOADING = 'SET_POST_ORG_DOC_LOADING';
const UPDATE_ORG_DOC_LOADING = 'UPDATE_ORG_DOC_LOADING';

const USER_ACTIVITIES = 'USER_ACTIVITIES';
const POST_DEPARTMENT_UNIT_LOADING = 'POST_DEPARTMENT_UNIT_LOADING';
const GET_DEPARTMENT_LOADING = 'GET_DEPARTMENT_LOADING';
const DEPARTMENTS_AND_UNITS = 'DEPARTMENTS_AND_UNITS';
const GET_DEPARTMENT_LIST_LOADING = 'GET_DEPARTMENT_LIST_LOADING';
const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
const POST_UNIT_LOADING = 'POST_UNIT_LOADING';
const UNIT_DATA = 'UNIT_DATA';
const GET_DEPARTMENT_GOAL_LOADING = 'GET_DEPARTMENT_GOAL_LOADING';
const DEPARTMENT_GOAL = 'DEPARTMENT_GOAL';
const DELETE_ORG_DEPT_LOADING = 'DELETE_ORG_DEPT_LOADING';
const GET_DEPARTMENT_UNIT_LOADING = 'GET_DEPARTMENT_UNIT_LOADING';
const DEPARTMENT_UNITS = 'DEPARTMENT_UNITS';
const GET_DEPARTMENT_UNIT_FILTER_LOADING = 'GET_DEPARTMENT_UNIT_FILTER_LOADING';
const DEPARTMENT_FILTER_UNITS = 'DEPARTMENT_FILTER_UNITS';
const SET_PARTNER_HISTORY = 'SET_PARTNER_HISTORY';
const SET_PARTNER_HISTORY_LOADING = 'SET_PARTNER_HISTORY_LOADING';
const SET_ADD_PARTNER_LOADING = 'SET_ADD_PARTNER_LOADING';
const SET_UPDATE_PARTNER_LOADING = 'SET_UPDATE_PARTNER_LOADING';
/*** REDUCERS ***/

const initialState = {
  orgAdminLoading: false,
  loading: false,
  organization: {},
  allOrganizations: [],
  orgGoals: [],
  organisationGoalsLoading: false,
  orgUsers: [],
  orgAdmins: [],
  orgUsersLoading: false,
  orgUsersList: {},
  orgProjects: [],
  orgVenues: [],
  orgContractors: [],
  orgPartners: [],
  orgActivityStats: [],
  orgInvoices: {},
  customLoading: {
    getOrgActivityStat: false,
    checkListPlanLoading: false,
    checkListReportLoading: false,
    ownedProjectLoading: false,
    invitedProjectLoading: false,
  },
  checkListPlan: {},
  checkListReport: {},
  userOwnedActivity: {},
  userInvitedActivity: {},
  reassignAll: false,
  reassignAnActivity: false,
  manageBtCardLoading: false,
  endSubLoading: false,
  orgInvoicesLoading: false,
  addSubscriptionLoading: false,
  emptyMessage: {},
  coreDataDelete: false,
  guestUserContractors: {},
  guestUserVenues: {},
  crossRefDocLoading: false,
  crossReferenceModificationLoading: false,
  crossRefDocuments: [],
  projectCrossReferences: [],
  projectCrossReferencesLoading: false,
  organisationCurrentRefData: {},
  organisationGoalsReferencesLoading: false,
  strategicLoading: false,
  strategicDocs: {},
  postOrgDocLoading: false,
  updateOrgDocLoading: false,
  userActivities: { completed: 0, inDevelopment: 0, inActive: 0 },
  postDeptLoading: false,
  getDeptLoading: false,
  departmentsUnits: {},
  deptListLoading: false,
  deptList: [],
  postUnitLoading: false,
  deptGoalsLoading: false,
  departmentGoals: [],
  deleteDepartmentLoading: false,
  departmentUnitLoading: false,
  departmentUnits: [],
  departmentFilterUnit: [],
  departmentFilterUnitLoading: false,
  partnerActHistory: {},
  partnerActHistoryLoading: false,
  addPartnerLoading: false,
  updatePartnerLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORG_ADMINS_LOADING:
      return { ...state, orgAdminLoading: action.payload.status };
    case SET_ORG_LOADING:
      return { ...state, loading: action.payload };

    case SET_ORG_LOADING_CUSTOM:
      return {
        ...state,
        customLoading: {
          ...state.customLoading,
          [action.payload.type]: action.payload.status,
        },
      };
    case SET_ORGANIZATION:
      return { ...state, organization: action.payload };
    case ORG_GOALS:
      return { ...state, orgGoals: action.payload };
    case ORG_ALL_GOALS:
      return { ...state, organisationGoals: action.payload };
    case ORG_ALL_GOALS_LOADING:
      return { ...state, organisationGoalsLoading: action.payload };
    case ORG_GOALS_REFERENCES:
      return { ...state, orgGoalsReferences: action.payload };
    case SET_ORG_USERS:
      return { ...state, orgUsers: action.payload };
    case SET_ORG_USERS_LOADING:
      return { ...state, orgUsersLoading: action.payload };
    case SET_ORG_USERS_LIST:
      return { ...state, orgUsersList: action.payload };
    case SET_ORG_GUEST_LOADING:
      return { ...state, orgGuestUsersLoading: action.payload };
    case SET_ORG_GUEST_USERS_LIST:
      return { ...state, orgGuestUsersList: action.payload };
    case SET_ORG_PROJECTS:
      return { ...state, orgProjects: action.payload };
    case SET_ORG_VENUES:
      return { ...state, orgVenues: action.payload };
    case SET_ORG_CONTRACTORS:
      return { ...state, orgContractors: action.payload };
    case SET_ORG_PARTNERS:
      return { ...state, orgPartners: action.payload };
    case SET_ORG_ACTIVITY_STATS:
      return { ...state, orgActivityStats: action.payload };
    case SET_ORG_CHECKLIST_PLAN:
      return { ...state, checkListPlan: action.payload };
    case SET_ORG_CHECKLIST_REPORT:
      return { ...state, checkListReport: action.payload };
    case SET_ALL_ORG:
      return { ...state, allOrganizations: action.payload };
    case SET_ORG_ADMINS:
      return { ...state, orgAdmins: action.payload };
    case SET_ORGANIZATION_CORE_DATA:
      return { ...state, orgCoreData: action.payload };
    case SET_USER_OWNED_ACTIVITY:
      return { ...state, userOwnedActivity: action.payload };
    case SET_USER_INVITED_ACTIVITY:
      return { ...state, userInvitedActivity: action.payload };
    case SET_ORG_LOADING_REASSIGN_ALL:
      return { ...state, reassignAll: action.payload };
    case SET_ORG_LOADING_REASSIGN_AN_ACTIVITY:
      return { ...state, reassignAnActivity: action.payload };
    case SET_CARD_LOADING:
      return { ...state, manageBtCardLoading: action.payload };
    case END_SUB_LOADING:
      return { ...state, endSubLoading: action.payload };
    case SET_INVOICE:
      return { ...state, orgInvoices: action.payload };
    case SET_INVOICE_LOADING:
      return { ...state, orgInvoicesLoading: action.payload };
    case START_SUB_LOADING:
      return { ...state, addSubscriptionLoading: action.payload };
    case GET_USER_ORG_EMPTY_MSG:
      return { ...state, emptyMessage: action.payload };
    case SET_GUEST_USER_CONTRACTORS:
      return { ...state, guestUserContractors: action.payload };

    case SET_CORE_DATA_REMOVE_LOADING:
      return { ...state, coreDataDelete: action.payload };
    case SET_GUEST_USER_VENUES:
      return { ...state, guestUserVenues: action.payload };
    case SET_CROSS_REF_DOC_LOADING:
      return { ...state, crossRefDocLoading: action.payload };
    case SET_CROSS_REF_DOCUMENTS:
      return { ...state, crossRefDocuments: action.payload };
    case SET_ORG_CURRENT_CROSS_REF_DATA:
      return { ...state, organisationCurrentRefData: action.payload };
    case ORGANISATION_GOALS_REFERENCES_LOADING:
      return { ...state, organisationGoalsReferencesLoading: action.payload };
    case STRATEGIC_DOCUMENTS_LOADING:
      return { ...state, strategicLoading: action.payload };
    case STRATEGIC_DOCUMENTS:
      return { ...state, strategicDocs: action.payload };
    case ORGANISATION_GOALS_REFERENCES_MODIFICATION_LOADING:
      return { ...state, crossReferenceModificationLoading: action.payload };

    case UPDATE_ORG_DOC_LOADING:
      return { ...state, updateOrgDocLoading: action.payload };
    case SET_POST_ORG_DOC_LOADING:
      return { ...state, postOrgDocLoading: action.payload };
    case USER_ACTIVITIES:
      return { ...state, userActivities: action.payload };
    case POST_DEPARTMENT_UNIT_LOADING:
      return { ...state, postDeptLoading: action.payload };
    case GET_DEPARTMENT_LOADING:
      return { ...state, getDeptLoading: action.payload };
    case DEPARTMENTS_AND_UNITS:
      return { ...state, departmentsUnits: action.payload };

    case GET_DEPARTMENT_LIST_LOADING:
      return { ...state, deptListLoading: action.payload };
    case DEPARTMENT_LIST:
      return { ...state, deptList: action.payload };
    case POST_UNIT_LOADING:
      return { ...state, postUnitLoading: action.payload };

    case GET_DEPARTMENT_GOAL_LOADING:
      return { ...state, deptGoalsLoading: action.payload };

    case DEPARTMENT_GOAL:
      return { ...state, departmentGoals: action.payload };
    case DELETE_ORG_DEPT_LOADING:
      return { ...state, deleteDepartmentLoading: action.payload };
    case DEPARTMENT_UNITS:
      return { ...state, departmentUnits: action.payload };
    case GET_DEPARTMENT_UNIT_LOADING:
      return { ...state, departmentUnitLoading: action.payload };

    case DEPARTMENT_FILTER_UNITS:
      return { ...state, departmentFilterUnit: action.payload };

    case GET_DEPARTMENT_UNIT_FILTER_LOADING:
      return { ...state, departmentFilterUnitLoading: action.payload };

    case SET_PARTNER_HISTORY:
      return { ...state, partnerActHistory: action.payload };

    case SET_PARTNER_HISTORY_LOADING:
      return { ...state, partnerActHistoryLoading: action.payload };
    case SET_ADD_PARTNER_LOADING:
      return { ...state, addPartnerLoading: action.payload };
    case SET_UPDATE_PARTNER_LOADING:
      return { ...state, updatePartnerLoading: action.payload };
    default:
      return state;
  }
}

/*** ACTIONS ***/
export function getOrganization() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(`/organisation`);
      dispatch({ type: SET_ORGANIZATION, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOrganizationInfo(orgData, showMsg = true) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_ORG_LOADING, payload: true });
        const response = await axios.put(`/organisation`, orgData);
        dispatch({ type: SET_ORGANIZATION, payload: response.data });
        dispatch(getUserProfile());
        resolve(response.data);
        if (showMsg) {
          authMsg('success', [
            'Your Account Info has been successfully updated!',
            'Account Info',
          ]);
        }
        dispatch({ type: SET_ORG_LOADING, payload: false });
      } catch (error) {
        dispatch({ type: SET_ORG_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function updateCoreDataInfo(orgData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.put(`/organisation/coreDataInfo`, { orgData });
      dispatch(getCoreDataInfo(orgData.reportYear));
      authMsg('success', [
        'Your Account core-data has been successfully updated!',
        'Account Core Data',
      ]);
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getCoreDataInfo(reportYear) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(
        `/organisation/coreDataInfo/${reportYear}`
      );
      const coreData = !isEmpty(response.data)
        ? response.data
        : cloneDeep(ORGANISATION_CORE_DATA);

      dispatch({ type: SET_ORGANIZATION_CORE_DATA, payload: coreData });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}
export function updateOrganizationLogo(file, orgId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_ORG_LOADING, payload: true });
        let formData = new FormData();
        formData.set('logo', file);
        formData.set('orgId', orgId);
        await axios.put(`/organisation/logo`, formData);
        dispatch({ type: SET_ORG_LOADING, payload: false });
        resolve();
      } catch (error) {
        dispatch({ type: SET_ORG_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(error);
      }
    });
  };
}

export function getOrgGoals(page = 1, limit = LIMIT, documentId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(`/organisation/goals`, {
        params: {
          limit,
          page,
          documentId,
        },
      });
      dispatch({ type: ORG_GOALS, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgGoalRefList(documentId) {
  return async (dispatch) => {
    try {
      dispatch({ type: ORGANISATION_GOALS_REFERENCES_LOADING, payload: true });

      const response = await axios.get(
        `/organisation/document/${documentId}/cross-references`
      );
      let data = response.data;

      dispatch({ type: ORG_GOALS_REFERENCES, payload: data });
      dispatch({ type: ORGANISATION_GOALS_REFERENCES_LOADING, payload: false });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({ type: ORGANISATION_GOALS_REFERENCES_LOADING, payload: false });
    }
  };
}

export function getOrganizationGoals() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ORG_ALL_GOALS_LOADING,
        payload: true,
      });

      const response = await axios.get(`/organisation/goals-list`);

      dispatch({ type: ORG_ALL_GOALS, payload: response.data });

      dispatch({
        type: ORG_ALL_GOALS_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: ORG_ALL_GOALS_LOADING,
        payload: false,
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postCrossReferenceDocument(formData) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_CROSS_REF_DOC_LOADING, payload: true });
        await axios.post(`/organisation/reference-document`, formData);

        dispatch({ type: SET_CROSS_REF_DOC_LOADING, payload: false });
        authMsg('success', [
          'Cross reference document has been successfully created!',
          'Cross Reference Document',
        ]);
        resolve(true);
      } catch (error) {
        dispatch({ type: SET_CROSS_REF_DOC_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function putCrossReferenceDocument(documentId, formData) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_CROSS_REF_DOC_LOADING, payload: true });
        await axios.put(
          `/organisation/reference-document/${documentId}`,
          formData
        );

        dispatch(getCrossReferenceDocumentList());

        authMsg('success', [
          'Cross reference document has been successfully updated!',
          'Cross Reference Document',
        ]);
        dispatch({ type: SET_CROSS_REF_DOC_LOADING, payload: false });
        resolve(true);
      } catch (error) {
        dispatch({ type: SET_CROSS_REF_DOC_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function getCrossReferenceDocumentList() {
  return async (dispatch) => {
    try {
      dispatch({ type: ORGANISATION_GOALS_REFERENCES_LOADING, payload: true });

      const response = await axios.get(`/organisation/reference-document`);

      dispatch({ type: SET_CROSS_REF_DOCUMENTS, payload: response.data });
      dispatch({ type: ORGANISATION_GOALS_REFERENCES_LOADING, payload: false });
      // dispatch({ type: SET_CROSS_REF_DOC_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: ORGANISATION_GOALS_REFERENCES_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

// FIXME: Unused Function
export function postCreateOrgGoalReferences(
  goalId,
  documentId,
  referencesList = []
) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: ORGANISATION_GOALS_REFERENCES_MODIFICATION_LOADING,
          payload: true,
        });

        await axios.post(
          `/organisation/goal/${goalId}/reference-document/${documentId}/cross-references`,
          {
            referencesList,
          }
        );

        dispatch({
          type: ORGANISATION_GOALS_REFERENCES_MODIFICATION_LOADING,
          payload: false,
        });

        dispatch(getOrgGoalRefList(documentId));
        authMsg('success', [
          'Your Account goal references has been successfully created!',
          'Account Goal References',
        ]);
        resolve(true);
      } catch (error) {
        dispatch({
          type: ORGANISATION_GOALS_REFERENCES_MODIFICATION_LOADING,
          payload: false,
        });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

// FIXME: unused function
export function putOrgGoalReferences(
  goalId,
  documentId,
  goalCrossReferenceId,
  existingReferencesList,
  newReferencesList
) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: ORGANISATION_GOALS_REFERENCES_MODIFICATION_LOADING,
          payload: true,
        });
        await axios.put(
          `/organisation/goal/${goalId}/reference-document/${documentId}/goal-reference/${goalCrossReferenceId}`,
          {
            existingReferencesList,
            newReferencesList,
          }
        );

        dispatch(getOrgGoalRefList(documentId));
        dispatch({
          type: ORGANISATION_GOALS_REFERENCES_MODIFICATION_LOADING,
          payload: false,
        });

        resolve(true);
        authMsg('success', [
          'Your Account goal references has been successfully updated!',
          'Account Goal References',
        ]);
      } catch (error) {
        dispatch({
          type: ORGANISATION_GOALS_REFERENCES_MODIFICATION_LOADING,
          payload: false,
        });
        reject(false);
        authMsg('error', getCommonError(error));
      }
    });
  };
}

export function putOrgArchivedGoalReferences(
  oldGoalId,
  newGoalId,
  documentId,
  goalCrossReferenceId,
  references
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });

      await axios.put(
        `/organisation/old-goal/${oldGoalId}/new-goal/${newGoalId}/archived-document/${documentId}/goal-reference/${goalCrossReferenceId}`,
        {
          references,
        }
      );
      authMsg('success', [
        'Your Account goal references has been successfully updated!',
        'Account Goal References',
      ]);
      dispatch({ type: SET_ORG_LOADING, payload: false });
      dispatch(getOrgGoalRefList(documentId));
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteOrgGoalRefList(goalId, documentId, referencesList) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });

      await axios.delete(
        `/organisation/goal/${goalId}/document/${documentId}/cross-references`
      );
      authMsg('success', [
        'Your Account goal references has been successfully deleted!',
        'Account Goal References',
      ]);
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function archiveOrgGoalReferences(
  documentId,
  goalId,
  goalCrossReferenceId
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });

      await axios.put(
        `/goal/${goalId}/goal-cross-reference/${goalCrossReferenceId}/archive-references`
      );
      authMsg('success', [
        'Your Account goal references has been successfully archived!',
        'Account Goal References',
      ]);
      dispatch({ type: SET_ORG_LOADING, payload: false });
      dispatch(getOrgGoalRefList(documentId));
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgCurrentCrossReferenceGoalData() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });

      const response = await axios.get(`/organisation/cross-references`);
      dispatch({
        type: SET_ORG_CURRENT_CROSS_REF_DATA,
        payload: response.data,
      });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgStrategicDocuments() {
  return async (dispatch) => {
    try {
      dispatch({ type: STRATEGIC_DOCUMENTS_LOADING, payload: true });
      const response = await axios.get(`/organisation/strategic-documents`);
      dispatch({
        type: STRATEGIC_DOCUMENTS,
        payload: response.data,
      });
      dispatch({ type: STRATEGIC_DOCUMENTS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: STRATEGIC_DOCUMENTS_LOADING, payload: false });

      authMsg('error', getCommonError(error));
    }
  };
}

export function createOrganizationGoal(goalData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.post(`/organisation/goal`, goalData);
      authMsg('success', [
        'Your Account goal has been successfully created!',
        'Account Goal',
      ]);

      await dispatch(getEmptyMessage());
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOrganizationGoal(goalId, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.put(`/organisation/goal/${goalId}`, data);
      authMsg('success', [
        'Your Account goal has been successfully updated!',
        'Account Goal Info',
      ]);
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteOrganizationGoal(goalId, data = {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.delete(`/organisation/goal/${goalId}`);
      authMsg('success', [
        'Your Account goal has been successfully deleted!',
        'Account Goal Info',
      ]);
      await dispatch(getOrgGoals(data.currentPage, LIMIT, data.documentId));
      await dispatch(getEmptyMessage());
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteOrganizationCoreData(coreId, documentId, reportYear) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_CORE_DATA_REMOVE_LOADING, payload: true });
      await axios.delete(
        `/organisation/core-data/${coreId}/document/${documentId}`
      );
      dispatch(getCoreDataInfo(reportYear));
      authMsg('success', [
        'The document in Core Data has been successfully removed.',
        'Account Core Data',
      ]);
      dispatch({ type: SET_CORE_DATA_REMOVE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_CORE_DATA_REMOVE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function uploadOrgDocuments(formData, documentId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: UPDATE_ORG_DOC_LOADING, payload: true });
        const response = await axios.put(
          `/organisation/document/${documentId}`,
          formData,
          { timeout: 120000 }
        );
        dispatch({ type: UPDATE_ORG_DOC_LOADING, payload: false });
        await dispatch(getOrgStrategicDocuments());
        dispatch(getOrganization());
        authMsg('success', [
          'Your Account strategic plan has been successfully uploaded!',
          'Account Goal',
        ]);
        resolve();
        dispatch(setOrgGoalDocument({ goalDocument: response.data }));
      } catch (error) {
        authMsg('error', getCommonError(error));
        dispatch({ type: UPDATE_ORG_DOC_LOADING, payload: false });
        reject(getCommonError(error));
      }
    });
  };
}

export function postOrgDocuments(formData, currentPage) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_POST_ORG_DOC_LOADING, payload: true });
        const response = await axios.post(
          `/organisation/goal-document`,
          formData,
          { timeout: 120000 }
        );
        dispatch(getOrganization());
        await dispatch(
          getOrgGoals(currentPage, LIMIT, response.data.organisationStrategicId)
        );
        dispatch({ type: SET_POST_ORG_DOC_LOADING, payload: false });
        await dispatch(getOrgStrategicDocuments());
        authMsg('success', [
          'Your Account strategic plan has been successfully uploaded!',
          'Account Goal',
        ]);
        resolve();
        dispatch(setOrgGoalDocument({ goalDocument: response.data }));
      } catch (error) {
        authMsg('error', getCommonError(error));
        dispatch({ type: SET_POST_ORG_DOC_LOADING, payload: false });
        reject(getCommonError(error));
      }
    });
  };
}

export function uploadOrgAnnualReport(formData) {
  return async (dispatch) => {
    try {
      await axios.put(`/organisation/report`, formData, {
        timeout: 120000,
      });
      authMsg('success', [
        'Your Annual Report plan has been successfully uploaded!',
        'Annual Report',
      ]);
      dispatch(getOrganization());
      dispatch(getCoreDataInfo(formData.reportingYear));
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrganizationUsers() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(`/organisation/users`);
      dispatch({ type: SET_ORG_USERS, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrganizationUsersList(
  page = 1,
  limit = LIMIT,
  departmentId = null,
  unitId = null
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_USERS_LOADING, payload: true });
      const response = await axios.get(`/organisation/user-list`, {
        params: {
          limit,
          page,
          departmentId,
          unitId,
        },
      });
      dispatch({ type: SET_ORG_USERS_LIST, payload: response.data });
      dispatch({ type: SET_ORG_USERS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_USERS_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrganizationGuestUsersList(
  page = 1,
  activity = null,
  name = null,
  limit = LIMIT
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_GUEST_LOADING, payload: true });
      const params = {
        limit,
        page,
      };
      if (activity) {
        params.activity = activity;
      }
      if (name) {
        params.name = name;
      }
      const response = await axios.get(`/organisation/guest-list`, {
        params,
      });
      dispatch({
        type: SET_ORG_GUEST_USERS_LIST,
        payload: response.data,
      });
      dispatch({ type: SET_ORG_GUEST_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_GUEST_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function createOrganizationUsers(formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.post(`/organisation/user`, formData);
      authMsg('success', [
        'Account user has been successfully created!',
        'Account User',
      ]);
      // await dispatch(getAllUsersOrg());
      await dispatch(getOrganizationUsers());
      await dispatch(getOrganizationUsersList());
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOrgUserType(userId, formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.put(`/organisation/user/${userId}/role`, formData);
      authMsg('success', [
        'Account user type has been successfully updated!',
        'Account User',
      ]);
      await dispatch(getOrganizationUsersList());
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function resetUserPassword(formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.put(
        `/organisation/user/${formData.id}`,
        pick(formData, ['password'])
      );
      authMsg('success', [
        'Account user password has been successfully reset!',
        'Account User',
      ]);
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function resetUserPasswordAdmin(formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.put(
        `/user/reset-password/${formData.id}`,
        pick(formData, ['password'])
      );
      authMsg('success', [
        'Your password has been successfully reset!',
        'Reset password',
      ]);
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function reassignAllOrgActivities(formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING_REASSIGN_ALL, payload: true });
      const response = await axios.put(
        `/organisation/reassign-activities`,
        formData
      );
      authMsg('success', [`${response.data.message}!`, 'Account User']);
      await dispatch(getOrgProjects());
      dispatch({ type: SET_ORG_LOADING_REASSIGN_ALL, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING_REASSIGN_ALL, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function reassignOrgActivity(projectId, formData) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: SET_ORG_LOADING_REASSIGN_AN_ACTIVITY,
          payload: true,
        });
        const response = await axios.put(`/organisation/reassign-activity`, {
          user: Number(formData.user),
          activity: Number(projectId),
          reassignType: formData.reassignType,
        });
        authMsg('success', [
          formData.reassignType === 'allActivity'
            ? 'Activities successfully reassigned'
            : 'Activity successfully reassigned',
          'Account User',
        ]);
        await dispatch(getOrgProjects());
        resolve(response.data);

        dispatch({
          type: SET_ORG_LOADING_REASSIGN_AN_ACTIVITY,
          payload: false,
        });
      } catch (error) {
        dispatch({
          type: SET_ORG_LOADING_REASSIGN_AN_ACTIVITY,
          payload: false,
        });
        authMsg('error', getCommonError(error));
        reject();
      }
    });
  };
}

export function reassignGuestOrgActivity(formData) {
  return async () => {
    try {
      await axios.put(`/organisation/reassign-guest-activity`, formData);
      authMsg('success', [
        'Account guest user activity has been successfully reassigned!',
        'Account Guest User',
      ]);
    } catch (error) {
      throw error;
    }
  };
}

export function getOrgProjects() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(`/organisation/projects`);
      dispatch({ type: SET_ORG_PROJECTS, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgVenues(keyword, activityId = '') {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(
        `/organisation/venues${keyword ? `?keyword=${keyword}` : ``}`,
        {
          params: {
            activityId,
          },
        }
      );
      dispatch({ type: SET_ORG_VENUES, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getGuestVenues(userId, keyword) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(
        `/guest-user/${userId}/venues${keyword ? `?keyword=${keyword}` : ``}`
      );
      dispatch({ type: SET_GUEST_USER_VENUES, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgVenuesList(page = 1, limit = LIMIT) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(`/organisation/venue-list`, {
        params: {
          limit,
          page,
        },
      });
      dispatch({ type: SET_ORG_VENUES, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function createOrgVenues(formData, projectId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_ORG_LOADING, payload: true });
        const response = await axios.post(`/organisation/venue`, formData);
        if (!projectId) {
          authMsg('success', [
            'Account venue has been successfully created!',
            'Account Venues',
          ]);
          await dispatch(getOrgVenuesList());
        } else {
          await dispatch(
            assignProjectVenue('projectInput', projectId, {
              venue: response.data,
            })
          );
        }
        dispatch({ type: SET_ORG_LOADING, payload: false });
        resolve(true);
      } catch (error) {
        dispatch({ type: SET_ORG_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject();
      }
    });
  };
}

export function updateOrgVenues(venueId, formData, projectId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        delete formData.createdAt;
        delete formData.updatedAt;
        dispatch({ type: SET_ORG_LOADING, payload: true });
        await axios.put(`/organisation/venue/${venueId}`, formData);
        if (!projectId) {
          authMsg('success', [
            'Account venue has been successfully updated!',
            'Account Venues',
          ]);
          await dispatch(getOrgVenuesList());
        } else {
          await dispatch(getProjectVenue('projectInput', projectId));
        }
        dispatch({ type: SET_ORG_LOADING, payload: false });
        resolve(true);
      } catch (error) {
        dispatch({ type: SET_ORG_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject();
      }
    });
  };
}

export function deleteOrgVenues(venueId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.delete(`/organisation/venue/${venueId}`);
      authMsg('success', [
        'Account venue has been successfully deleted!',
        'Account Venues',
      ]);
      await dispatch(getOrgVenuesList());
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgContractors(keyword, activityId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(
        `/organisation/contractors${keyword ? `?keyword=${keyword}` : ``}`,
        {
          params: {
            activityId,
          },
        }
      );
      dispatch({ type: SET_ORG_CONTRACTORS, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getGuestContractors(userId, keyword) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(
        `/guest-user/${userId}/contractors${
          keyword ? `?keyword=${keyword}` : ``
        }`
      );
      dispatch({ type: SET_GUEST_USER_CONTRACTORS, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgContractorsList(page = 1, limit = LIMIT) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(`/organisation/contractor-list`, {
        params: {
          limit,
          page,
        },
      });
      dispatch({ type: SET_ORG_CONTRACTORS, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function createOrgContractor(formData, projectId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_ORG_LOADING, payload: true });
        const response = await axios.post(`/organisation/contractor`, formData);
        dispatch({ type: SET_ORG_LOADING, payload: false });

        if (!projectId) {
          authMsg('success', [
            'Account contractor has been successfully created!',
            'Account Contractor',
          ]);
          await dispatch(getOrgContractorsList());
        } else {
          await dispatch(
            assignProjectContractor('projectInput', projectId, {
              contractor: response.data,
            })
          );
        }
        resolve(true);
      } catch (error) {
        dispatch({ type: SET_ORG_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function updateOrgContractor(contractorId, formData, projectId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_ORG_LOADING, payload: true });
        await axios.put(`/organisation/contractor/${contractorId}`, formData);
        dispatch({ type: SET_ORG_LOADING, payload: false });

        if (!projectId) {
          await dispatch(getOrgContractorsList());
        } else {
          await dispatch(getProjectContractor('projectInput', projectId));
        }
        authMsg('success', [
          'Account contractor has been successfully updated!',
          'Account Contractor',
        ]);
        resolve(true);
      } catch (error) {
        dispatch({ type: SET_ORG_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function deleteOrgContractor(contractorId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.delete(`/organisation/contractor/${contractorId}`);
      authMsg('success', [
        'Account contractor has been successfully deleted!',
        'Account Contractor',
      ]);
      await dispatch(getOrgContractorsList());
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function createOrgPartner(formData, projectId = null) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_ADD_PARTNER_LOADING, payload: true });

        let orgFormData = formData;

        // remove spaced string
        orgFormData.firstName =
          orgFormData.firstName && orgFormData.firstName.trim();
        orgFormData.secondName =
          orgFormData.secondName && orgFormData.secondName.trim();
        orgFormData.email = orgFormData.email && orgFormData.email.trim();
        orgFormData.phone = orgFormData.phone ? orgFormData.phone.trim() : null;
        orgFormData.activityId = projectId;

        const response = await axios.post(`/organisation/partner`, orgFormData);
        dispatch({ type: SET_ADD_PARTNER_LOADING, payload: false });

        if (!projectId) {
          authMsg('success', [
            'Account partner has been successfully created!',
            'Account Partner',
          ]);
          await dispatch(getOrgPartners());
        } else {
          const newPartnerData = omit(response.data, [
            'createdAt',
            'updatedAt',
          ]);
          const partner = {
            ...newPartnerData,
            description: formData.description,
            supportType: formData.supportType,
            isAnonymous:
              formData.firstName === 'Anonymous Donor' ? true : false,
          };
          await dispatch(
            assignProjectPartner('projectInput', projectId, {
              partner,
            })
          );
        }
        resolve(true);
      } catch (error) {
        dispatch({ type: SET_ADD_PARTNER_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function getOrgPartners(page = 1, limit = LIMIT) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(`/organisation/partners`, {
        params: {
          limit,
          page,
        },
      });
      dispatch({ type: SET_ORG_PARTNERS, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgPartnersByKeywords(keyword) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(
        `/organisation/search-partners${keyword ? `?keyword=${keyword}` : ``}`
      );
      dispatch({ type: SET_ORG_PARTNERS, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgPartnersHistory(partnerId, page) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_PARTNER_HISTORY_LOADING, payload: true });
      const response = await axios.get(
        `/partner/${partnerId}/activity-history`,
        {
          params: {
            limit: LIMIT,
            page: page,
          },
        }
      );
      dispatch({ type: SET_PARTNER_HISTORY, payload: response.data });
      dispatch({ type: SET_PARTNER_HISTORY_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_PARTNER_HISTORY_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOrgPartner(
  partnerId,
  formData,
  projectId,
  isAssignProject = false
) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_UPDATE_PARTNER_LOADING, payload: true });

        // this is getting from activity need to check whether already assigned partner for this project
        if (projectId && isAssignProject) {
          dispatch(
            assignProjectPartner('projectInput', projectId, {
              partner: {
                ...formData,
                id: partnerId,
                isAnonymous:
                  formData.firstName === 'Anonymous Donor' ? true : false,
              },
            })
          );
          return;
        }

        let orgFormData = formData;
        if (orgFormData.hasOwnProperty('isAnonymous')) {
          delete orgFormData.isAnonymous;
          orgFormData.email = orgFormData.email.trim();
          orgFormData.phone = orgFormData.phone.trim();
        }

        orgFormData.activityId = projectId;

        await axios.put(`/organisation/partner/${partnerId}`, orgFormData);

        dispatch({ type: SET_UPDATE_PARTNER_LOADING, payload: false });

        if (!projectId) {
          await dispatch(getOrgPartners());
          authMsg('success', [
            'Account partner has been successfully updated!',
            'Account Partner',
          ]);
        } else {
          if (!isAssignProject)
            await dispatch(
              updateProjectPartner(
                'projectInput',
                projectId,
                partnerId,
                {
                  description: formData.description,
                  supportType: formData.supportType,
                  isAnonymous:
                    formData.firstName === 'Anonymous Donor' ? true : false,
                },
                true
              )
            );
          await dispatch(getProjectPartner('projectInput', projectId));
        }

        resolve(true);
      } catch (error) {
        dispatch({ type: SET_UPDATE_PARTNER_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function deleteOrgPartner(partnerId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.delete(`/organisation/partner/${partnerId}`);
      authMsg('success', [
        'Account partner has been successfully deleted!',
        'Account Partner',
      ]);
      await dispatch(getOrgPartners());
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}
export function getOrgActivityStat() {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: {
          type: 'getOrgActivityStat',
          status: true,
        },
      });
      const response = await axios.get(`/organisation/activity-stats`);
      dispatch({ type: SET_ORG_ACTIVITY_STATS, payload: response.data });
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: {
          type: 'getOrgActivityStat',
          status: false,
        },
      });
    } catch (error) {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: {
          type: 'getOrgActivityStat',
          status: false,
        },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteOrganizationUser(userId, isWithdrawnActivities = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      await axios.delete(`/organisation/user/${userId}`);
      if (isWithdrawnActivities) {
        authMsg('success', [
          'Your Account user all invited activities has been withdrawn!',
          'Account User',
        ]);
      }
      authMsg('success', [
        'Your Account user has been successfully deleted!',
        'Account User',
      ]);

      await dispatch(getOrganizationUsersList());
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

/**
 * organization checklist
 * ----------------------------
 */

export function getOrgChecklistPlan() {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: true },
      });
      const response = await axios.get(`/organisation/checklist-plan`);
      dispatch({ type: SET_ORG_CHECKLIST_PLAN, payload: response.data });
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: false },
      });
    } catch (error) {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function createChecklistPlan(plan) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: true },
      });
      await axios.post(`/organisation/checklist-plan`, plan);
      authMsg('success', [
        'Your Account checklist item has been successfully created!',
        'Account Checklist for Activity Planing',
      ]);
      await dispatch(getOrgChecklistPlan());
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: false },
      });
    } catch (error) {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateChecklistPlan(planId, plan) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: true },
      });
      await axios.put(`/organisation/checklist-plan/${planId}`, plan);
      authMsg('success', [
        'Your Account checklist item has been successfully updated!',
        'Account Checklist for Activity Planing',
      ]);
      await dispatch(getOrgChecklistPlan());
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: false },
      });
    } catch (error) {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteChecklistPlan(planId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: {
          type: 'checkListPlanLoading',
          status: true,
        },
      });
      await axios.delete(`/organisation/${planId}`);
      authMsg('success', [
        'Your Account checklist item has been successfully deleted!',
        'Account Checklist for Activity Planing',
      ]);
      await dispatch(getOrgChecklistPlan());
    } catch (error) {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListPlanLoading', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgChecklistReport() {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: true },
      });
      const response = await axios.get(`/organisation/checklist-report`);
      dispatch({
        type: SET_ORG_CHECKLIST_REPORT,
        payload: response.data,
      });
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: false },
      });
    } catch (error) {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function createChecklistReport(report) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: true },
      });
      await axios.post(`/organisation/checklist-report`, report);
      authMsg('success', [
        'Your Account checklist item has been successfully created!',
        'Get Account Checklist for Activity Reporting',
      ]);
      await dispatch(getOrgChecklistReport());
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: false },
      });
    } catch (error) {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateChecklistReport(reportId, report) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: true },
      });
      await axios.put(`/organisation/checklist-report/${reportId}`, report);
      authMsg('success', [
        'Your Account checklist item has been successfully updated!',
        'Get Account Checklist for Activity Reporting',
      ]);
      await dispatch(getOrgChecklistReport());
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: false },
      });
    } catch (error) {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteChecklistReport(reportId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: true },
      });
      await axios.delete(`/organisation/checklist-report/${reportId}`);
      authMsg('success', [
        'Your Account checklist item has been successfully deleted!',
        'Get Account Checklist for Activity Reporting',
      ]);
      await dispatch(getOrgChecklistReport());
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: false },
      });
    } catch (error) {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: 'checkListReportLoading', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getAllOrganisationList() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/organisations');
      dispatch({ type: SET_ALL_ORG, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrganisationAdmins() {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_ADMINS_LOADING,
        payload: { type: 'orgAdminLoading', status: true },
      });
      const response = await axios.get(`/organisation/users`, {
        params: {
          type: 'organisationAdmin',
        },
      });
      dispatch({ type: SET_ORG_ADMINS, payload: response.data });
      dispatch({
        type: SET_ORG_ADMINS_LOADING,
        payload: { type: 'orgAdminLoading', status: false },
      });
    } catch (error) {
      dispatch({
        type: SET_ORG_ADMINS_LOADING,
        payload: { type: 'orgAdminLoading', status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOwnedActivitiesByUser(
  loadingType,
  userId,
  status = 'all',
  type = 'all',
  page = 1,
  limit = LIMIT
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: loadingType, status: true },
      });
      const response = await axios.get(`/user/${userId}/owned-project`, {
        params: {
          limit,
          page,
          status,
          type,
        },
      });
      dispatch({ type: SET_USER_OWNED_ACTIVITY, payload: response.data });
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: loadingType, status: false },
      });
    } catch (error) {
      dispatch({ type: SET_USER_OWNED_ACTIVITY, payload: [] });
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: loadingType, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getInvitedActivitiesByUser(
  loadingType,
  userId,
  status = 'all',
  type = 'all',
  page = 1,
  limit = LIMIT
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: loadingType, status: true },
      });
      const response = await axios.get(`/user/${userId}/invited-project`, {
        params: {
          limit,
          page,
          status,
          type,
        },
      });
      dispatch({
        type: SET_USER_INVITED_ACTIVITY,
        payload: response.data,
      });
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: loadingType, status: false },
      });
    } catch (error) {
      dispatch({ type: SET_USER_INVITED_ACTIVITY, payload: [] });
      dispatch({
        type: SET_ORG_LOADING_CUSTOM,
        payload: { type: loadingType, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function addCard(organisationId, body) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_CARD_LOADING, payload: true });
      await axios.post(`/account/${organisationId}/card-add`, body);
      dispatch(getUserProfileOnly());
      dispatch(
        getInvoices(organisationId, {
          status: 'all',
        })
      );
      authMsg('success', [
        'Account card has been successfully added!',
        'Account Subscription',
      ]);
      dispatch({ type: SET_CARD_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_CARD_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateCard(organisationId, body) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_CARD_LOADING, payload: true });
      await axios.put(`/account/${organisationId}/card-update`, body);
      dispatch(getUserProfileOnly());
      authMsg('success', [
        'Account card has been successfully updated!',
        'Account Subscription',
      ]);
      dispatch({ type: SET_CARD_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_CARD_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function endSubscription(organisationId) {
  return async (dispatch) => {
    try {
      dispatch({ type: END_SUB_LOADING, payload: true });
      await axios.delete(`/account/${organisationId}/end-subscription`);
      dispatch(getUserProfileOnly());
      authMsg('success', [
        'Account subscription has been successfully ended!',
        'Account Subscription',
      ]);
      history.push('/account');
      dispatch({ type: END_SUB_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: END_SUB_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function newSubscription(organisationId, body) {
  return async (dispatch) => {
    try {
      dispatch({ type: START_SUB_LOADING, payload: true });
      await axios.post(`/account/${organisationId}/subscribe`, body);
      dispatch(getUserProfileOnly());
      authMsg('success', [
        'New account subscription type has been successfully added!',
        'Account Subscription',
      ]);
      history.push('/account');
      dispatch({ type: START_SUB_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: START_SUB_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getEmptyMessage() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/user-organisation/empty`);
      dispatch({ type: GET_USER_ORG_EMPTY_MSG, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function retrySubscription(organisationId, body) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_INVOICE_LOADING, payload: true });
      await axios.put(`/account/${organisationId}/pay-now`, body);
      dispatch(getUserProfileOnly());
      dispatch(
        getInvoices(organisationId, {
          status: 'all',
        })
      );
      authMsg('success', [
        'Payment has been successfully added!',
        'Account Subscription',
      ]);
      dispatch({ type: SET_INVOICE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_INVOICE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getInvoices(
  organisationId,
  {
    status = 'all',
    order = 'desc',
    trialCheck = false,
    page = 1,
    limit = LIMIT,
  }
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_INVOICE_LOADING, payload: true });
      const response = await axios.get(`/account/${organisationId}/invoices`, {
        params: {
          limit,
          page,
          status,
          order,
          trialCheck,
        },
      });
      dispatch({ type: SET_INVOICE, payload: response.data });
      dispatch({ type: SET_INVOICE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_INVOICE, payload: false });
      dispatch({ type: SET_INVOICE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getUserActivitiesCount(userId) {
  return async (dispatch) => {
    try {
      const userActivities = await axios.get(
        `/user/${userId}/activities-count`
      );

      dispatch({
        type: USER_ACTIVITIES,
        payload: userActivities.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function postOrganisationDepartment(data) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: POST_DEPARTMENT_UNIT_LOADING, payload: true });
        await axios.post(`/organisation/department`, data);
        dispatch(await getDepartmentsUnits());
        dispatch(await getDepartmentsLists());

        dispatch({ type: POST_DEPARTMENT_UNIT_LOADING, payload: false });
        authMsg('success', [
          'Department/Unit has been successfully created!',
          'Department/Unit',
        ]);
        resolve(true);
      } catch (error) {
        dispatch({ type: POST_DEPARTMENT_UNIT_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function getDepartmentsUnits() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEPARTMENT_LOADING, payload: true });
      const dept = await axios.get(`/organisation/departments`);
      dispatch({
        type: DEPARTMENTS_AND_UNITS,
        payload: dept.data,
      });
      dispatch({ type: GET_DEPARTMENT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_DEPARTMENT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getDepartmentsLists() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEPARTMENT_LIST_LOADING, payload: true });
      const dept = await axios.get(`/organisation/departments-list`);

      dispatch({
        type: DEPARTMENT_LIST,
        payload: dept.data,
      });
      dispatch({ type: GET_DEPARTMENT_LIST_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_DEPARTMENT_LIST_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getDepartmentsListsByProgram(programId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEPARTMENT_LIST_LOADING, payload: true });
      const dept = await axios.get(
        `/project/${programId}/organisation-departments-list`
      );

      dispatch({
        type: DEPARTMENT_LIST,
        payload: dept.data,
      });
      dispatch({ type: GET_DEPARTMENT_LIST_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_DEPARTMENT_LIST_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getDepartmentGoals(deptId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEPARTMENT_GOAL_LOADING, payload: true });
      const goal = await axios.get(`/department/${deptId}/goals`);
      dispatch({
        type: DEPARTMENT_GOAL,
        payload: goal.data,
      });
      dispatch({ type: GET_DEPARTMENT_GOAL_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_DEPARTMENT_GOAL_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getDepartmentUnits(deptId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEPARTMENT_UNIT_LOADING, payload: true });
      const deptUnit = await axios.get(`/department/${deptId}/units`);
      dispatch({
        type: DEPARTMENT_UNITS,
        payload: deptUnit.data,
      });
      dispatch({ type: GET_DEPARTMENT_UNIT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_DEPARTMENT_UNIT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getDepartmentFilterUnits(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEPARTMENT_UNIT_FILTER_LOADING, payload: true });
      const deptUnit = await axios.get(`/department/units`, {
        params: {
          departments: JSON.stringify(data),
        },
      });
      dispatch({
        type: DEPARTMENT_FILTER_UNITS,
        payload: deptUnit.data,
      });
      dispatch({ type: GET_DEPARTMENT_UNIT_FILTER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_DEPARTMENT_UNIT_FILTER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postOrganisationUnit(data) {
  return async (dispatch, getState) => {
    try {
      const departmentId = data.departmentId;
      delete data.departmentId;
      dispatch({ type: POST_UNIT_LOADING, payload: true });
      const unit = await axios.post(
        `/organisation/department/${departmentId}/unit`,
        data
      );
      dispatch(await getDepartmentsUnits());
      dispatch(await getDepartmentsLists());
      dispatch({
        type: UNIT_DATA,
        payload: unit.data,
      });
      authMsg('success', ['Unit has been successfully created!', 'Unit']);
      dispatch({ type: POST_UNIT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: POST_UNIT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteOrganisationDepartment(deptId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_ORG_DEPT_LOADING, payload: true });
      await axios.delete(`/organisation/department/${deptId}`);
      dispatch(await getDepartmentsUnits());
      dispatch(await getDepartmentsLists());
      authMsg('success', [
        'Department/Unit has been successfully Deleted!',
        'Organisation Department/Unit',
      ]);
      dispatch({ type: DELETE_ORG_DEPT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DELETE_ORG_DEPT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOrganizationDepartment(data, deptId) {
  return async (dispatch) => {
    try {
      await axios.put(`/organisation/department/${deptId}`, data);
      dispatch(await getDepartmentsUnits());
      dispatch(await getDepartmentsLists());
      authMsg('success', [
        'Department has been successfully updated!',
        'Department',
      ]);
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOrganizationUnit(data, unitId) {
  return async (dispatch) => {
    try {
      await axios.put(`/organisation/unit/${unitId}`, data);
      dispatch(await getDepartmentsUnits());
      dispatch(await getDepartmentsLists());
      authMsg('success', [
        'Department has been successfully updated!',
        'Department',
      ]);
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}
export function assignDepartmentToUser(id, data) {
  return async (dispatch) => {
    try {
      await axios.put(`/user/${id}/details`, data);
      dispatch(await getOrganizationUsersList(1, LIMIT));
      authMsg('success', ['User has been assigned to the department', 'User']);
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrganizationUsersByAdmin(organisationId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING, payload: true });
      const response = await axios.get(`/organisation/${organisationId}/users`);
      dispatch({ type: SET_ORG_USERS, payload: response.data });
      dispatch({ type: SET_ORG_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function reassignAllOrgActivitiesByAdmin(orgId, formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ORG_LOADING_REASSIGN_ALL, payload: true });
      const response = await axios.put(
        `/organisation/${orgId}/reassign-activities`,
        formData
      );
      authMsg('success', [`${response.data.message}!`, 'Account User']);
      await dispatch(getOrgProjects(orgId));
      dispatch({ type: SET_ORG_LOADING_REASSIGN_ALL, payload: false });
    } catch (error) {
      dispatch({ type: SET_ORG_LOADING_REASSIGN_ALL, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}
