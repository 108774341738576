/* eslint-disable no-eval */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { Segment, Grid, Icon, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import { forEach, isEmpty, sortBy, map, get } from '../../../../utils/lodash';
import '../activity.scss';
import thousands from 'thousands';
import { isParentProgramActivity } from '../../../../utils/activity';

class ActivityCheckBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      confirm: false,
    };
  }

  componentDidMount() {
    const { actual, currentActivity, item } = this.props;
    let value = false;
    const outputObj = currentActivity.output.find(
      (outputItem) => outputItem.outputFormatId === item.outputFormatId
    );
    let checkBoxValue = '0';

    if (outputObj) {
      checkBoxValue = actual ? outputObj.actualValue : outputObj.projectedValue;
    }

    if (checkBoxValue) {
      value = checkBoxValue === '0' || checkBoxValue === '0.00' ? false : true;
    }

    this.setState({
      isChecked: value,
    });
  }

  render() {
    const { isChecked } = this.state;
    const { item } = this.props;
    return (
      <div className="outputs-col-div">
        <div className="income-div">
          <label>{item.label}</label>
          <div> {isChecked ? 'Yes' : 'No'}</div>
        </div>
      </div>
    );
  }
}

class ActivityOutput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      coreParticipant: false,
      confirm: false,
      applicationCloseDateError: '',
      form: {
        actualBudget: props.currentActivity.actualBudget
          ? thousands(props.currentActivity.actualBudget)
          : '0',
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentActivity } = this.props;
    if (prevProps.currentActivity !== currentActivity) {
      this.setState({
        form: {
          ...this.state.form,
          actualBudget: currentActivity.actualBudget
            ? thousands(currentActivity.actualBudget)
            : '0',
        },
      });
    }
  }

  calculateFormula(formatsWithFormula) {
    try {
      const { actual } = this.props;
      let formulaObject = { output: [] };

      forEach(formatsWithFormula, async (item) => {
        let newFormula = item.formula;
        this.props.currentActivity.eventDetail.outputFormat.format.forEach(
          (item2) => {
            const outputObj = this.props.currentActivity.output.find(
              (outputItem) => outputItem.outputFormatId === item2.outputFormatId
            );
            let getValue = '0';

            if (outputObj) {
              getValue = actual
                ? parseFloat(outputObj.actualValue)
                  ? parseFloat(outputObj.actualValue)
                  : '0'
                : parseFloat(outputObj.projectedValue)
                  ? parseFloat(outputObj.projectedValue)
                  : '0';
            }
            if (getValue) {
              newFormula = newFormula.replace(
                new RegExp('\\b' + item2.name + '\\b', 'g'),
                getValue
              );
            }
          }
        );
        const value = eval(newFormula).toFixed(2);
        formulaObject.output.push({
          outputFormatId: item.outputFormatId,
          [`${actual ? 'actualValue' : 'projectedValue'}`]: value ? value : '0',
        });
      });
      return formulaObject;
    } catch (error) {
      // console.error('calculateFormula -> error', error);
      throw error;
    }
  }

  getReadOnly(item) {
    if (item.formula && item.formula !== '') return true;
    return item.readonly;
  }

  getValues(actual, currentActivity, item) {
    const { form } = this.state;
    // what is item.id
    const type = `${actual ? 'actualOutput' : 'projectedOutput'}${item.order}`;

    const outputObj = currentActivity.output.find(
      (outputItem) => outputItem.outputFormatId === item.outputFormatId
    );
    let itemValue = actual
      ? get(outputObj, 'actualValue', '0')
      : get(outputObj, 'projectedValue', '0');

    let getValue;

    if (form[type]) {
      getValue = form[type];
    } else {
      if (item.type === 'date') {
        getValue = itemValue;
      } else {
        getValue = thousands(itemValue) ? thousands(itemValue) : '0';
      }
    }
    // moment(itemValue).format('DD-MM-YYYY')

    if (item.type !== 'currency') {
      getValue = getValue.replace(/\.00$/, '');
    }

    if (item.name === 'totalParticipants' && actual) {
      //pass the calculated actual participant for the total participant input
      getValue = thousands(currentActivity.actualParticipants);
    }

    return getValue;
  }

  getProjectedValues(item) {
    const { form } = this.state;
    const { currentActivity } = this.props;
    const type = `${'projectedOutput'}${item.order}`;

    const outputObj = currentActivity.output.find(
      (outputItem) => outputItem.outputFormatId === item.outputFormatId
    );
    let getValue = '0';

    if (outputObj) {
      getValue = form[type]
        ? form[type]
        : thousands(outputObj.projectedValue)
          ? thousands(outputObj.projectedValue)
          : '0';
    }

    if (item.type !== 'currency') {
      getValue = getValue.replace(/\.00$/, '');
    }

    let results = `${'Projected '}${item.label} : ${getValue}`;

    return results;
  }

  getInputType(type, currencyIconName = 'dollar sign') {
    switch (type) {
      case 'currency':
        return (
          <InputGroupAddon addonType="prepend">
            <Icon circular inverted name={currencyIconName} color="grey" />
          </InputGroupAddon>
        );
      case 'percentage':
        return <InputGroupAddon addonType="prepend">%</InputGroupAddon>;
      default:
        break;
    }
  }

  renderInputs(actual, currentActivity) {
    const { putActivity } = this.props;
    if (isEmpty(currentActivity.eventDetail)) return null;
    const inputs =
      sortBy(currentActivity.eventDetail.outputFormat.format, (o) => {
        return o.order;
      }) || [];

    if (isEmpty(inputs)) return null;

    const currencyType = get(currentActivity, 'currencyType');
    const currencySymbol = get(currencyType, 'sign', '$');

    return (
      <>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          className="outputs-col"
        >
          <div className="outputs-col-div">
            <label className="income-title">Activity Data</label>
          </div>
        </Grid.Column>
        {map(inputs, (item, i) => {
          switch (item.type) {
            case 'checkbox':
              return (
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={6}
                  className="outputs-col "
                  key={i}
                >
                  <div className="outputs-col-div act-data-checkbox">
                    <ActivityCheckBox
                      item={item}
                      actual={actual}
                      currentActivity={currentActivity}
                      putActivity={(type, data) => putActivity(type, data)}
                    />
                  </div>
                </Grid.Column>
              );
            case 'date':
              const date = this.getValues(actual, currentActivity, item);
              return (
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={8}
                  className="outputs-col"
                >
                  <div className="outputs-col-div">
                    <div className="income-div">
                      <label>{item.label}</label>
                    </div>
                    <div>{date ? date : ' - '}</div>
                  </div>
                </Grid.Column>
              );
            default:
              const value = this.getValues(actual, currentActivity, item);
              return (
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={6}
                  className="outputs-col"
                  key={i}
                >
                  <div className="outputs-col-div">
                    <div className="income-div">
                      <label>{item.label}</label>
                      <div>
                        {item.type === 'currency' && value && currencySymbol}
                        {value ? value : ' - '}
                        {item.type === 'percentage' && value && '%'}
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              );
          }
        })}
      </>
    );
  }

  render() {
    const { form: forms } = this.state;
    const { actual, currentActivity, actPut } = this.props;

    const actualTotalCost =
      currentActivity && currentActivity.parentId
        ? +currentActivity.actualGrantAmount +
          +currentActivity.actualBudget -
          currentActivity.actualCost
        : +currentActivity.actualBudget - currentActivity.actualCost;

    const form = {
      totalCost: actual && actualTotalCost ? actualTotalCost.toFixed(2) : '0',
    };
    if (actPut.activityData) return null;

    const currencyType = get(currentActivity, 'currencyType');
    const currencySymbol = get(currencyType, 'sign', '$');

    return (
      <Segment className="outputs-segment">
        <form ref={(c) => (this.form = c)}>
          <Grid>
            <Grid.Row className="outputs-row icon-input-filed ">
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={6}
                className="outputs-col"
              >
                <div className="outputs-col-div">
                  <div className="income-div">
                    <label>Other Income</label>
                    <div>
                      {forms.actualBudget ? (
                        <>
                          {currencySymbol}
                          {forms.actualBudget}
                        </>
                      ) : (
                        ' - '
                      )}
                    </div>
                  </div>
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={8}
                computer={6}
                className="outputs-col"
              >
                <div className="outputs-col-div">
                  <div className="income-div">
                    <label>
                      {`${actual ? 'Actual' : 'Projected'} ${
                        isParentProgramActivity(
                          currentActivity.activityPlanTypeId
                        )
                          ? 'program'
                          : 'activity'
                      } final result`}
                    </label>
                    <div>
                      {form.totalCost ? (
                        <>
                          {currencySymbol}
                          {thousands(form.totalCost)}
                        </>
                      ) : (
                        ' - '
                      )}
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Divider section></Divider>
            <Grid.Row className="outputs-row">
              {this.renderInputs(actual, currentActivity)}
            </Grid.Row>
          </Grid>
        </form>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

ActivityOutput = connect(mapStateToProps, mapDispatchToProps)(ActivityOutput);

export { ActivityOutput };
